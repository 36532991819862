import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { appName } from '../../../.application.json';
import { getSiteTranslations } from '../../config/i18n';
import { getGogDataAndCovers } from '../../services/gogHelper';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { appParams, setProps, config, compId } = controllerConfig;
  const {
    fedopsLogger,
    getExperiments,
    getSiteLanguage,
    widgetId,
    isSSR,
    isMobile,
    inEditor,
  } = flowAPI;

  // Read more about fedops and how to configure it: https://bo.wix.com/wix-docs/client/client-viewer-platform/articles/fedops#client-viewer-platform_articles_fedops_fedops
  fedopsLogger.appLoadStarted();
  const language = getSiteLanguage();
  const [experiments, translations] = await Promise.all([
    getExperiments(),
    getSiteTranslations(language),
  ]);
  const { baseUrls = {}, appDefinitionId, instance, instanceId } = appParams;
  let albumsCovers: GalleryItem[] = [];
  let coverIdToAlbum: { [id: string]: GogAlbum } = {};
  let gogData: GogData | { albums: GogAlbum[] } = { albums: [] };

  const defaultAlbumsIds =
    config &&
    config.publicData &&
    config.publicData.APP &&
    config.publicData.APP.defaultAlbums &&
    config.publicData.APP.defaultAlbums.ids;

  let shouldUseImageToken;
  try {
    shouldUseImageToken = Boolean(
      experiments.get('specs.albums.useWatermarkImageToken'),
    );
  } catch (e) {
    console.error('Failed to get token experiment');
  }

  try {
    const result = await getGogDataAndCovers(
      instanceId,
      instance,
      compId,
      defaultAlbumsIds,
      shouldUseImageToken,
    );
    gogData = result.gogData;
    albumsCovers = result.albumsCovers;
    coverIdToAlbum = result.coverIdToAlbum;
  } catch (e) {
    console.error('Failed to fetch GoG data - ' + e.message);
  }

  const setHeightFunc = (height: number) => setProps({ dimensions: height });
  return {
    async pageReady() {
      setProps({
        appName,
        cssBaseUrl: baseUrls.staticsBaseUrl,
        language,
        mobile: isMobile(),
        experiments: experiments.all(),
        translations,
        instance,
        instanceId,
        setHeight: setHeightFunc,
        albumsCovers,
        gogData,
        coverIdToAlbum,
        inEditor,
      });

      // report loaded SSR of widget
      if (isSSR()) {
        fedopsLogger.appLoaded({ appId: appDefinitionId, widgetId });
      }
    },
  };
};

export default createController;
